import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useToastContext } from "../../contexts/ToastContext";
import Toast from "../../molecules/Toast";

import {
  Container,
} from './Toasts.styles';

export interface ToastsProps {}

const Toasts: React.VFC<ToastsProps> = () => {
  const toastContext = useToastContext();
  const $portal = useMemo(() => {
    const $element = document.createElement('div');
    document.body.appendChild($element);
    return $element;
  }, []);

  const children = (
    <Container>
      {toastContext.toasts.map((toast, index) => (
        <Toast
          key={index}
          {...toast}
        />
      ))}
    </Container>
  )

  return ReactDOM.createPortal(children, $portal);
}

export default Toasts;
