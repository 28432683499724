import Text from "../../atoms/Text";

export interface ToastProps {
  variant?: 'success' | 'warning' | 'danger' | 'info';
  headline?: string;
  message?: string;
}

const Toast: React.FC<ToastProps> = ({
  variant = 'info',
  headline,
  message
}) => {
  return (
    <>
      {/* <Text>{variant}</Text> */}
      <Text>{headline}</Text>
      <Text>{message}</Text>
    </>
  );
}

export default Toast;
