export interface InputProps {
  type?: 'text' | 'password';
  placeholder?: string;
  isDisabled?: boolean;
  ciSelector?: string;
  value: string;
  autoFocus?: boolean;
  onChangeText: (text: string) => void;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  value,
  placeholder,
  isDisabled,
  onChangeText,
  autoFocus,
  ciSelector,
}) => {
  return (
    <input
      type={type}
      value={value}
      disabled={isDisabled}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={(e) => onChangeText(e.target.value)}
      data-ci={ciSelector}
    />
  );
}

export default Input;
