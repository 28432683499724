import Button, { ButtonProps } from "../../atoms/Button";
import Heading from "../../atoms/Heading";
import ImageComponent from "../../atoms/Image";
import Link from "../../atoms/Link";
import { Entity } from "../../types";

export interface RecipeViewerProps {
  recipe: Entity<{
    id: string;
    title: string;
    description: string;
    instructions: {
      id: string;
      text: string;
    }[];
    ingredients: {
      id: string;
      text: string;
    }[];
    image?: {
      signedUrl: string;
      blurHash: string;
    } | null;
  }>;
  editText: string;
}

const RecipeViewer: React.FC<RecipeViewerProps> = ({
  recipe,
  editText,
}) => {

  return (
    <>
      {(() => {
          if (recipe.status === 'loading') {
            return <div>loading</div>
          }

          if (recipe.status === 'error') {
            return <div>{recipe.error}</div>
          }

          return (
            <div>
              <Heading as="h1">{recipe.data.title}</Heading>
              <p>{recipe.data.description}</p>

              {recipe.data.image && (
                <ImageComponent
                  url={recipe.data.image.signedUrl}
                  blurHash={recipe.data.image.blurHash}
                />
              )}

              <Heading as="h4">Ingredients</Heading>

              <ul>
                {recipe.data.ingredients.map((ingredient) => (
                  <li key={ingredient.id}>
                    {ingredient.text}
                  </li>
                ))}
              </ul>

              <Heading as="h4">Instructions</Heading>

              <ul>
                {recipe.data.instructions.map((instruction) => (
                  <li key={instruction.id}>
                    {instruction.text}
                  </li>
                ))}
              </ul>

              <Link to={`/recipes/${recipe.data.id}/edit`}>
                <Button
                  text={editText}
                />
              </Link>
            </div>
          );
        })()}
    </>
  );
}

export default RecipeViewer;
