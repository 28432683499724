import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import Navigation from "../../../atomic/organisms/Navigation";

const NavigationContainer = () => {
  const { t } = useTranslation('navigation');
  const routeMatch = useRouteMatch();

  return (
    <Navigation
      items={[
        {
          title: t('recipes'),
          icon: 'recipes',
          to: '/recipes',
          isActive: routeMatch.path.startsWith('/recipes'),
        },
        {
          title: t('settings'),
          icon: 'settings',
          to: '/settings',
          isActive: routeMatch.path.startsWith('/settings'),
        },
      ]}
    />
  );
}

export default NavigationContainer;
