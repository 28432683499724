import { detect } from 'detect-browser';
import { v4 as uuid } from 'uuid';

import ClientStorage from './ClientStorage';

const DEVICE_ID_KEY = 'UNIQUE_DEVICE_ID';

export default class DeviceInfo {
  public static async getId() {
    let deviceId = await ClientStorage.get<string>(DEVICE_ID_KEY);

    if (!deviceId) {
      deviceId = uuid();
      await ClientStorage.set<string>(DEVICE_ID_KEY, deviceId);
    }

    return deviceId;
  }
  
  public static getInfo() {
    return {
      userAgent: window.navigator.userAgent,
      ...detect(),
    };
  }
}
