type IngredientHeadline = {
  id: string;
  type: 'headline';
  text: string;
};

type IngredientItem = {
  id: string;
  type: 'item';
  text: string;
};

export type Ingredient = IngredientHeadline | IngredientItem;

type InstructionHeadline = {
  id: string;
  type: 'headline';
  text: string;
};

type InstructionItem = {
  id: string;
  type: 'item';
  text: string;
};

export type Instruction = InstructionHeadline | InstructionItem;

export type Image = {
  signedUrl: string;
  blurHash: string;
  key: string;
  ext: string;
  filename: string;
  mimeType: string;
};

export type Recipe = {
  title: string;
  description: string;
  image?: Image;
  ingredients: Ingredient[];
  instructions: Instruction[];
}

export type State = {
  recipe: Recipe;
}

export const initialState: State = {
  recipe: {
    title: '',
    description: '',
    ingredients: [],
    instructions: [],
  }
}