import Center from "../../layout/Center";

export interface AuthTemplateProps {
  form: React.ReactNode;
  footer: React.ReactNode;
}

const AuthTemplate: React.FC<AuthTemplateProps> = ({
  form,
  footer,
}) => {
  return (
    <Center>
      {form}
      {footer}
    </Center>
  );
}

export default AuthTemplate;
