import { QueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ErrorMap, getErrorCodes } from "../../utils/errors";

type ErrorResolver = (errorCodes: ErrorMap) => string;
type DataResolver<T, K> = (query: T) => K;

export const useQueryResult = <T, K = T>(
  query: QueryResult<T>,
  dataResolver?: DataResolver<T, K>,
  errorResolver?: ErrorResolver
) => {
  const { t } = useTranslation('general');

  const defaultDataResolver = (data: T): K => data as unknown as K;

  const defaultErrorResolver: ErrorResolver = (errorCodes) => {
    if (errorCodes.NETWORK_REQUEST_FAILED) {
      return t('general.network');
    }

    return t('general.unknown');
  }

  const finalDataResolver = dataResolver || defaultDataResolver;
  const finalErrorResolver = errorResolver || defaultErrorResolver;

  if (!query.loading && query.error) {
    return {
      status: 'error' as const,
      error: finalErrorResolver(getErrorCodes(query.error)),
    }
  }

  if (!query.loading && query.data) {
    return {
      status: 'response' as const,
      data: finalDataResolver(query.data),
    }
  }

  return {
    status: 'loading' as const,
  };
}