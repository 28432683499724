import ClientStorage from "./ClientStorage";

const SESSION_TOKEN_KEY = 'SESSION_TOKEN_KEY';

export default class SessionService {
  public static async getToken() {
    return await ClientStorage.get<string>(SESSION_TOKEN_KEY) ?? undefined;
  }

  public static async isLoggedIn() {
    return !!(await SessionService.getToken());
  }

  public static async login(sessionToken: string) {
    await ClientStorage.set<string>(SESSION_TOKEN_KEY, sessionToken);
  }
  
  public static async signup(sessionToken: string) {
    await ClientStorage.set<string>(SESSION_TOKEN_KEY, sessionToken);
  }

  public static async logout() {
    await ClientStorage.remove(SESSION_TOKEN_KEY);
  }
}
