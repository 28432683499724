import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  to: string;
  target?: '_blank';
  onPress?: () => void;
}

const Link: React.FC<LinkProps> = ({
  to,
  target,
  onPress,
  children,
}) => {
  return (
    <RouterLink
      to={to}
      target={target}
      onClick={onPress}
    >
      {children}
    </RouterLink>
  )
}

export default Link;
