import { useCallback, useMemo } from "react";
import { Toast, useToastContext } from "../contexts/ToastContext";

export const useToast = () => {
  const toastContext = useToastContext();

  const show = useCallback((toast: Toast) => {
    toastContext.add(toast);
  }, [toastContext]);

  const error = useCallback((message: string) => {
    show({ variant: 'info', message });
  }, [show]);

  const api = useMemo(() => ({
    show,
    error,
  }), [show, error])

  return api;
};