export interface BoxProps {
}

const Box: React.FC<BoxProps> = ({
  children,
}) => {
  return (
    <div>
      {children}
    </div>
  );
}

export default Box;
