import { useCallback, useState } from "react";

export const useError = () => {
  const [error, setError] = useState<string>();

  const resetError = useCallback(() => {
    setError(undefined);
  }, []);

  return [error, setError, resetError] as const;
};