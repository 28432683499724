import { useTranslation } from "react-i18next";
import RecipeViewer from "../../../atomic/organisms/RecipeViewer";
import RecipeTemplate from "../../../atomic/templates/RecipeTemplate";
import NavigationContainer from "../../containers/NavigationContainer";
import { useRecipePageService } from "./service";

export interface RecipePageProps {
}

const RecipePage: React.FC<RecipePageProps> = () => {
  const { t } = useTranslation('recipe');

  const {
    recipe
  } = useRecipePageService();

  return (
    <RecipeTemplate
      navigation={<NavigationContainer />}
      content={(
        <RecipeViewer
          recipe={recipe}
          editText={t('edit')}
        />
      )}
    />
  );
}

export default RecipePage;
