import Styled from 'styled-components';

export const Container = Styled.div`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  max-width: calc(100% - 2rem);
  transform: translateX(-50%);

  & > * {
    margin: 0.5rem 0;
  }
`;