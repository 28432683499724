import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Link from "../../../atomic/atoms/Link";
import AuthForm from "../../../atomic/organisms/AuthForm";
import AuthTemplate from "../../../atomic/templates/AuthTemplate";
import FooterContainer from "../../containers/FooterContainer";

import { useAuthContainerService } from "./service";

export interface AuthContainerProps {
  i18nNamespace: 'login' | 'signup',
}

const AuthContainer: React.FC<AuthContainerProps> = ({
  i18nNamespace
}) => {
  const { t } = useTranslation(i18nNamespace);

  const {
    state,
    error,
    email,
    submitButton,
    onSubmit,
    onReset,
  } = useAuthContainerService(i18nNamespace);

  return (
    <AuthTemplate
      form={(
        <AuthForm
          state={state}

          headline={t('headline')}
          description={t('description')}
          successMessage={t('successMessage')}
          errorMessage={error}

          email={email}

          submitButton={submitButton}

          onSubmit={onSubmit}

          switchLink={(
            <Trans t={t} i18nKey="switchLink" components={{
              to: <Link to={i18nNamespace === 'login' ? '/sign-up' : '/login'} />
            }} />
          )}

          resetLink={(
            <Trans t={t} i18nKey="resetLink" components={{
              to: <Link
                to={i18nNamespace === 'signup' ? '/sign-up' : '/login'}
                onPress={onReset}
              />
            }} />
          )}
        />
      )}
      footer={<FooterContainer />}
    />
  );
}

export default AuthContainer;
