import { useCallback, useState } from "react";
import { List } from 'react-movable';
import IngredientEditor, { IngredientEditorProps } from "../IngredientEditor";

export interface IngredientsEditorProps {
  ingredients: IngredientEditorProps['ingredient'][];
  onRemove: (ingredientId: string) => void;
  onUpdate: (ingredientId: string, text: string) => void;
  onAdd: (index: number) => void;
  onMove: (oldIndex: number, newIndex: number) => void;
}

const IngredientsEditor: React.FC<IngredientsEditorProps> = ({
  ingredients,
  onRemove,
  onUpdate,
  onAdd,
  onMove,
}) => {
  const [indexFocus, setIndexFocus] = useState(-1);

  const onClickAdd = useCallback(() => {
    onAdd(ingredients.length);
    setIndexFocus(ingredients.length);
  }, [onAdd, ingredients]);

  const onKeyAdd = useCallback((ingredientId: string) => {
    const index = ingredients.findIndex(ingredient => ingredient.id === ingredientId);
    onAdd(index + 1);
    setIndexFocus(index + 1);
  }, [onAdd, ingredients]);
  
  const onKeyRemove = useCallback((ingredientId: string) => {
    const index = ingredients.findIndex(ingredient => ingredient.id === ingredientId);
    onRemove(ingredientId);
    setIndexFocus(index - 1);
  }, [onRemove, ingredients]);

  return (
    <>
      <List
        values={ingredients}
        onChange={({ oldIndex, newIndex }) => {
          onMove(oldIndex, newIndex);
        }}
        renderList={({ children, props }) => (
          <div {...props}>
            {children}
          </div>
        )}
        renderItem={({ value: ingredient, props, index }) => (
          <div {...props}>
            <IngredientEditor
              ingredient={ingredient}
              autoFocus={indexFocus === index}
              onKeyAdd={() => onKeyAdd(ingredient.id)}
              onKeyRemove={() => onKeyRemove(ingredient.id)}
              onRemove={() => onRemove(ingredient.id)}
              onUpdate={(text) => onUpdate(ingredient.id, text)}
            />
          </div>
        )}
      />

      <button type="button" onClick={onClickAdd}>
        Add
      </button>
    </>
  );
}

export default IngredientsEditor;
