import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useToast } from "../../../atomic/hooks/useToast";
import { SessionUserRecipesQuery, useCreateRecipeMutation, useDeleteRecipeMutation, useSessionUserRecipesQuery } from "../../../graphql/generated";
import { useQueryResult } from "../../../hooks/helpers/useQueryResult";

export const useRecipesPageService = () => {
  const { t } = useTranslation('recipes');
  const history = useHistory();
  const toast = useToast();
  // const recipesQuery = useSessionUserRecipesQuery({ nextFetchPolicy: 'cache-first' });
  const recipesQuery = useSessionUserRecipesQuery();
  const [createRecipe, createRecipeData] = useCreateRecipeMutation();
  const [deleteRecipe] = useDeleteRecipeMutation();
  const isCreating = createRecipeData.loading;

  const recipes = useQueryResult<SessionUserRecipesQuery, SessionUserRecipesQuery['sessionUser']['recipes']>(recipesQuery,
    (data) => data.sessionUser.recipes,
    () => t('errors.load')
  );

  const onCreate = useCallback(async () => {
    try {
      const response = await createRecipe({
        variables: {
          title: t('newRecipeTitle'),
        },
      });

      history.push(`/recipes/${response.data!.createRecipe.id}`);
    } catch (error) {
      toast.error(t('errors.create'));
    }
  }, [createRecipe, history, t, toast]);

  const onDelete = useCallback(async (recipeId: string) => {
    try {
      await deleteRecipe({
        variables: {
          recipeId,
        },
        update: (cache) => {
          cache.evict({ id: cache.identify({ id: recipeId, __typename: 'Recipe' }), broadcast: false });
          cache.gc();
        }
      });
    } catch (error) {
      toast.error(t('errors.remove'));
    }
  }, [deleteRecipe, t, toast]);

  return {
    isCreating,
    onCreate,
    onDelete,
    recipes,
  }
}

