import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useSession } from "../../../hooks/services/useSession";
import HomePage from "../HomePage";

const EntryPage = () => {
  const [page, setPage] = useState<'home' | 'login'>();
  const session = useSession();

  useEffect(() => {
    ;(async () => {
      if (await session.isLoggedIn()) {
        setPage('home');
      } else {
        setPage('login');
      }
    })();
  }, [session]);

  if (page  === 'home') {
    return <HomePage />
  }

  if (page  === 'login') {
    return <Redirect to="/login" />
  }

  return null;
}

export default EntryPage;
