import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useRecipeQuery, useUpdateRecipeMutation } from '../../../graphql/generated';
import { useUploadImage } from '../../../hooks/helpers/useUploadImages';

import { initialState, State } from './editor/state';
import StateApi, { History } from './editor/api';
import Uuid from '../../../services/Uuid';

export const useRecipeEditorPageService = () => {
  const { recipeId } = useParams<{ recipeId: string }>();
  const [updateRecipe] = useUpdateRecipeMutation();
  const [uploadImage] = useUploadImage();
  const editor = useMemo(() => new StateApi(), []);

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [lastSavedState, setLastSavedState] = useState<State>();
  const [state, setState] = useState<State>(initialState);
  const [history, setHistory] = useState<History>();

  useEffect(() => {
    editor.onChange((newState, newHistory) => {
      if (!lastSavedState) setLastSavedState(newState);

      setState(newState);
      setHistory(newHistory);
      
    });
  }, [editor, lastSavedState]);

  const hasUnsavedChanges = useMemo(() => (
    JSON.stringify(lastSavedState) !== JSON.stringify(state)
  ), [lastSavedState, state]);

  useRecipeQuery({
    variables: {
      recipeId,
    },
    onCompleted: (data) => {
      if (data.recipe) {
        editor.api.setRecipe(data.recipe);
      }

      setLoading(false);
    }
  });

  const onImageChange = async (files: Blob[]) => {
    try {
      const image = await uploadImage(files[0]);
      editor.api.setImage(image);
    } catch (error) {
      console.log(error);
    }
  }

  const onImageRemove = () => {
    editor.api.setImage(undefined);
  }

  const onSave = useCallback(async () => {
    try {
      setSaving(true);

      await updateRecipe({
        variables: {
          recipeId: recipeId,
          title: state.recipe.title,
          description: state.recipe.description,
          ingredients: state.recipe.ingredients.map((ingredient) => ({
            id: ingredient.id,
            type: ingredient.type,
            text: ingredient.text,
          })),
          instructions: state.recipe.instructions.map((instruction) => ({
            id: instruction.id,
            type: instruction.type,
            text: instruction.text,
          })),
          image: state.recipe.image ? {
            key: state.recipe.image.key,
            blurHash: state.recipe.image.blurHash,
            ext: state.recipe.image.ext,
            filename: state.recipe.image.filename,
            mimeType: state.recipe.image.mimeType,
          } : undefined,
        },
      });

      setLastSavedState(state);
    } catch (error) {
      console.log(error);
      // NETWORK_REQUEST_FAILED
      // UNKNOWN_ERROR
      // PERMISSION_DENIED
    } finally {
      setSaving(false);
    }
  }, [updateRecipe, recipeId, state]);

  const onAddIngredient = useCallback((index: number) => {
    editor.api.addIngredient(index, {
      id: Uuid.generate(),
      type: 'item',
      text: '',
    })
  }, [editor]);

  const onAddInstruction = useCallback((index: number) => {
    editor.api.addInstruction(index, {
      id: Uuid.generate(),
      type: 'item',
      text: '',
    })
  }, [editor]);

  return {
    isLoading,
    isSaving,
    onSave,

    undo: editor.api.undo,
    redo: editor.api.redo,
    hasUnsavedChanges,
    canUndo: history?.canUndo,
    canRedo: history?.canRedo,
    
    recipeId,
    title: state.recipe.title,
    description: state.recipe.description,
    ingredients: state.recipe.ingredients,
    instructions: state.recipe.instructions,
    image: state.recipe.image ? {
      url: state.recipe.image.signedUrl,
      blurHash: state.recipe.image.blurHash,
    } : undefined,

    onTitleChange: editor.api.setTitle,
    onDescriptionChange: editor.api.setDescription,
    onImageChange,
    onImageRemove,

    onAddIngredient,
    onUpdateIngredient: editor.api.updateIngredient,
    onRemoveIngredient: editor.api.removeIngredient,
    onMoveIngredient: editor.api.moveIngredient,

    onAddInstruction,
    onUpdateInstruction: editor.api.updateInstruction,
    onRemoveInstruction: editor.api.removeInstruction,
    onMoveInstruction: editor.api.moveInstruction,
  }
}

