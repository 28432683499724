import { produceWithPatches, enablePatches, Patch, applyPatches, setAutoFreeze } from 'immer';

import { State, initialState } from './state';

import {
  setRecipe,
  setTitle,
  setDescription,
  setImage,
  addIngredient,
  removeIngredient,
  updateIngredient,
  moveIngredient,
  addInstruction,
  removeInstruction,
  updateInstruction,
  moveInstruction,
} from './actions';

enablePatches();
setAutoFreeze(false);

type StackItem = {
  options?: ActionHistory;
  doPatches: Patch[],
  undoPatches: Patch[],
  lastUpdatedAt: number;
};

export type History = {
  stack: StackItem[];
  cursor: number;
  canUndo: boolean;
  canRedo: boolean;
}

type ActionHistory = {
  throttle?: {
    key: string;
    delay: number;
  };
};

type ActionHistoryResult = boolean | ActionHistory;
type ActionHistoryParam = ActionHistoryResult | ((...args: any[]) => ActionHistoryResult);

export type Action = {
  history?: ActionHistoryParam;
  do: (...args: any[]) => (state: State) => void
};

type Listener = (state: State, history: History) => void;

type DoAction = {
  history?: ActionHistory;
  do: (state: State) => void
};

class StateApi {
  private history: History = {
    stack: [],
    cursor: -1,
    canUndo: false,
    canRedo: false,
  }

  private state: State = initialState;

  private listener?: Listener;

  api = {
    undo: this.undoAction.bind(this),
    redo: this.redoAction.bind(this),
    setRecipe: this.bindAction(setRecipe),
    setTitle: this.bindAction(setTitle),
    setDescription: this.bindAction(setDescription),
    setImage: this.bindAction(setImage),
    addIngredient: this.bindAction(addIngredient),
    removeIngredient: this.bindAction(removeIngredient),
    updateIngredient: this.bindAction(updateIngredient),
    moveIngredient: this.bindAction(moveIngredient),
    addInstruction: this.bindAction(addInstruction),
    removeInstruction: this.bindAction(removeInstruction),
    updateInstruction: this.bindAction(updateInstruction),
    moveInstruction: this.bindAction(moveInstruction),
  }

  onChange(listener: Listener) {
    this.listener = listener;
  }

  private doAction(action: DoAction) {
    const [_, doPatches, undoPatches] = produceWithPatches(this.state, action.do);

    if (action.history) {
      const { stack, cursor } = this.history;
      const stackItem = stack[cursor];

      const newStackItem = {
        options: action.history,
        doPatches,
        undoPatches,
        lastUpdatedAt: Date.now(),
      };

      if (
        stackItem?.options?.throttle
        && newStackItem?.options?.throttle
        && stackItem.options.throttle.key === newStackItem.options.throttle.key
        && Date.now() - stackItem.lastUpdatedAt < newStackItem.options.throttle.delay
      ) {
        this.history.stack[cursor].doPatches = doPatches;
      } else {
        this.history.stack = [...stack.slice(0, cursor + 1), newStackItem];
        this.history.cursor = this.history.stack.length - 1;
        this.history.canUndo = this.history.cursor > -1;
        this.history.canRedo = this.history.cursor < this.history.stack.length - 1;
      }
    }

    this.applyPatches(doPatches);
  }

  private undoAction() {
    const { stack, cursor } = this.history;
    const stackItem = stack[cursor];

    if (!stackItem) return;

    this.history.stack[cursor] = stackItem;
    this.history.cursor = cursor - 1;
    this.history.canUndo = this.history.cursor > -1;
    this.history.canRedo = this.history.cursor < this.history.stack.length - 1;

    this.applyPatches(stackItem.undoPatches);
  }

  private redoAction() {
    const { stack, cursor } = this.history;
    const stackItem = stack[cursor + 1];

    if (!stackItem) return;
    
    this.history.stack[cursor + 1] = stackItem;
    this.history.cursor = cursor + 1;
    this.history.canUndo = this.history.cursor > -1;
    this.history.canRedo = this.history.cursor < this.history.stack.length - 1;

    this.applyPatches(stackItem.doPatches);
  }

  private bindAction <T extends Action>(action: T): (...args: Parameters<T['do']>) => void {
    return (...args: Parameters<T['do']>) => {
      let historyResult: ActionHistoryResult | undefined = typeof action.history === 'function'
        ? action.history(...args)
        : action.history;
      
      let history: ActionHistory | undefined = typeof historyResult === 'boolean'
        ? {}
        : historyResult;

      this.doAction({
        history,
        do: action.do(...args),
      });
    };
  }

  private applyPatches(patches: Patch[]) {
    const nextState = applyPatches(this.state, patches);
    this.setState(nextState);
  }

  private setState(state: State) {
    this.state = JSON.parse(JSON.stringify(state)) as State;
    this.notify();
  }

  private notify() {
    if (this.listener) {
      this.listener(this.state, this.history);
    }
  }
}

export default StateApi;