import { useEffect } from "react";

import { useLogoutPageService } from "./service";

const LogoutPage = () => {
  const {
    onLogout,
  } = useLogoutPageService();

  useEffect(() => {
    (async () => {
      await onLogout();
    })();
  }, [onLogout]);

  return null;
}

export default LogoutPage;
