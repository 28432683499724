import React from 'react';

export interface TextProps {
  as?: 'p' | 'span' | 'div';
}

const Text: React.FC<TextProps> = ({
  as = 'div',
  children,
}) => {
  return React.createElement(as, null, children);
}

export default Text;
