import styled from 'styled-components';

export const Container = styled.div`

`;

export const Navigation = styled.div`

`;

export const Content = styled.div`

`;
