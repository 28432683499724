import React from "react";
import Link from "../../atoms/Link";

export interface NavigationProps {
  items: {
    title: string;
    icon: 'recipes' | 'settings';
    to: string;
    isActive: boolean;
  }[];
}

const Navigation: React.FC<NavigationProps> = ({
  items,
}) => {
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Link to={item.to}>
            {item.isActive ? (
              <strong>{item.title}</strong>
            ) : (
              <span>{item.title}</span>
            )}
          </Link>
        </React.Fragment>
      ))}
    </>
  );
}

export default Navigation;
