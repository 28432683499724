import {
  Content,
} from './styles';

export interface ContainerProps {
}

const Center: React.FC<ContainerProps> = ({
  children,
}) => {
  return (
    <Content>
      {children}
    </Content>
  );
}

export default Center;
