import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useError } from "../../../hooks/helpers/useError";
import { useSession } from "../../../hooks/services/useSession";
import { useAuthorizeMutation } from "../../../graphql/generated";
import { getErrorCodes } from "../../../utils/errors";

export const useAuthorizePageService = () => {
  const { t } = useTranslation('authorize');
  const session = useSession();
  const [error, setError] = useError();
  const [authorize] = useAuthorizeMutation();
  const history = useHistory();
  const { token } = useParams<{ token: string; }>();

  const onSubmit = useCallback(async () => {
    try {
      const response = await authorize({
        variables: {
          token,
        },
      });

      await session.login(response.data!.authorize.sessionToken);
      history.push('/');
    } catch (error) {
      const errorCodes = getErrorCodes(error);

      if (errorCodes.INVALID_TOKEN) {
        setError(t('errors.invalidLink'));
      } else if (errorCodes.INVALID_INTENT) {
        setError(t('errors.invalidLink'));
      } else if (errorCodes.EXPIRED_INTENT) {
        setError(t('errors.expiredLink'));
      } else if (errorCodes.NETWORK_REQUEST_FAILED) {
        setError(t('errors.network'));
      } else {
        setError(t('errors.unknown'));
      }
    }
  }, [authorize, setError, session, history, t, token]);

  useEffect(() => {
    onSubmit()
  }, []);

  return {
    error,
  }
}

