import Page from "../../layout/Page";

export interface RecipesTemplateProps {
  navigation: React.ReactNode;
  grid: React.ReactNode;
}

const RecipesTemplate: React.FC<RecipesTemplateProps> = ({
  navigation,
  grid,
}) => {
  return (
    <Page
      navigation={navigation}
      content={grid}
    />
  );
}

export default RecipesTemplate;
