import React from "react";
import Box from "../../atoms/Box";
import Button, { ButtonProps } from "../../atoms/Button";
import Form from "../../atoms/Form";
import Heading from "../../atoms/Heading";
import Input from "../../atoms/Input";
import Text from "../../atoms/Text";
import FormControl from "../../molecules/FormControl";
import FormErrorMessage from "../../molecules/FormErrorMessage";
import { TextFormField } from "../../types/TextFormField";

export interface AuthFormProps {
  state: 'initial' | 'success';
  headline: string;
  description: string;
  successMessage: string;
  errorMessage?: string;
  email: TextFormField;
  submitButton: Pick<Required<ButtonProps>, 'text' | 'isLoadingText' | 'isLoading' | 'isDisabled'>;
  switchLink: React.ReactNode;
  resetLink: React.ReactNode;
  onSubmit: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  state,
  headline,
  description,
  errorMessage,
  email,
  switchLink,
  resetLink,
  successMessage,
  submitButton,
  onSubmit,
}) => {

  if (state === 'success') {
    return (
      <>
        <Text>{successMessage}</Text>
        
        <Box>{resetLink}</Box>
      </>
    );
  }

  return (
    <>
      <Heading as="h1">{headline}</Heading>
      <Text>{description}</Text>

      <Form onSubmit={onSubmit}>
        <FormControl
          label={email.label}
          error={email.error}
        >
          <Input
            value={email.value}
            placeholder={email.placeholder}
            isDisabled={email.isDisabled}
            onChangeText={email.onChange}
            autoFocus
            ciSelector="auth-form-email"
          />
        </FormControl>

        <FormErrorMessage
          message={errorMessage}
        />
        
        <Button
          type="submit"
          text={submitButton.text}
          isDisabled={submitButton.isDisabled}
          isLoading={submitButton.isLoading}
          isLoadingText={submitButton.isLoadingText}
          ciSelector="auth-form-submit"
        />

        <Box>{switchLink}</Box>
      </Form>

    </>
  );
}

export default AuthForm;
