import React, { useCallback, useContext, useState } from 'react';
import { ToastProps } from '../molecules/Toast';

export type Toast = ToastProps & {
  delay?: number;
}

export type IToastContext = {
  toasts: Toast[];
  add: (toast: Toast) => void;
}

const ToastContext = React.createContext<IToastContext | undefined>(undefined);

export const useToastContext = () => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error('ToastContext not initialized');
  }

  return toastContext;
}

export const useCreateToastContext = (): IToastContext => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const remove = useCallback((toast: Toast) => {
    setToasts(toasts => toasts.filter(existingToast => existingToast !== toast));
  }, []);

  const add = useCallback((toast: Toast) => {
    const newToast = {
      delay: 4000,
      ...toast,
    }

    setToasts(toasts => ([newToast, ...toasts]));
    setTimeout(() => remove(newToast), newToast.delay);
  }, [remove]);

  return {
    toasts: Object.values(toasts),
    add,
  };
}

export default ToastContext;