import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {"fetchPolicy":"network-only"} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  sessionToken: Scalars['String'];
};

export type CheckUpdates = {
  __typename?: 'CheckUpdates';
  hasUpdate: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  _empty?: Maybe<Scalars['String']>;
};

export type I18n = {
  __typename?: 'I18n';
  translations: Array<Maybe<I18nTranslation>>;
};

export type I18nTranslation = {
  __typename?: 'I18nTranslation';
  key: Scalars['String'];
  language: Scalars['String'];
  value: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  blurHash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  signedUrl: Scalars['String'];
};

export type ImageInput = {
  blurHash: Scalars['String'];
  ext: Scalars['String'];
  filename: Scalars['String'];
  key: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ImageUploadUrl = {
  __typename?: 'ImageUploadUrl';
  url: Scalars['String'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type IngredientInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type Instruction = {
  __typename?: 'Instruction';
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type InstructionInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  authorize: AuthResponse;
  createImageUploadUrl: ImageUploadUrl;
  createRecipe: Recipe;
  deleteRecipe?: Maybe<EmptyResponse>;
  logout?: Maybe<EmptyResponse>;
  registerDevice?: Maybe<EmptyResponse>;
  requestAuthorization?: Maybe<EmptyResponse>;
  updateRecipe: Recipe;
};


export type MutationAuthorizeArgs = {
  token: Scalars['String'];
};


export type MutationCreateRecipeArgs = {
  title?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteRecipeArgs = {
  recipeId: Scalars['ID'];
};


export type MutationRegisterDeviceArgs = {
  deviceId: Scalars['ID'];
  deviceInfo: Scalars['JSONObject'];
};


export type MutationRequestAuthorizationArgs = {
  email: Scalars['String'];
};


export type MutationUpdateRecipeArgs = {
  description: Scalars['String'];
  image?: InputMaybe<ImageInput>;
  ingredients: Array<InputMaybe<IngredientInput>>;
  instructions: Array<InputMaybe<InstructionInput>>;
  recipeId: Scalars['ID'];
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkUpdates: CheckUpdates;
  i18n: I18n;
  ping: Scalars['String'];
  recipe: Recipe;
  sessionUser: User;
  shortUrl: ShortUrl;
  user: User;
};


export type QueryCheckUpdatesArgs = {
  platform: Scalars['String'];
  version: Scalars['String'];
};


export type QueryPingArgs = {
  delay?: InputMaybe<Scalars['Int']>;
};


export type QueryRecipeArgs = {
  recipeId: Scalars['ID'];
};


export type QueryShortUrlArgs = {
  slug: Scalars['String'];
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};

export type Recipe = {
  __typename?: 'Recipe';
  authors: Array<Maybe<User>>;
  createdAt: Scalars['Float'];
  description: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<Image>;
  ingredients: Array<Ingredient>;
  instructions: Array<Instruction>;
  title: Scalars['String'];
};

export type ShortUrl = {
  __typename?: 'ShortUrl';
  id: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  recipes: Array<Recipe>;
};

export type AuthorizeMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AuthorizeMutation = { __typename?: 'Mutation', authorize: { __typename?: 'AuthResponse', sessionToken: string } };

export type CreateImageUploadUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateImageUploadUrlMutation = { __typename?: 'Mutation', createImageUploadUrl: { __typename?: 'ImageUploadUrl', url: string } };

export type CreateRecipeMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateRecipeMutation = { __typename?: 'Mutation', createRecipe: { __typename?: 'Recipe', id: string, title: string } };

export type DeleteRecipeMutationVariables = Exact<{
  recipeId: Scalars['ID'];
}>;


export type DeleteRecipeMutation = { __typename?: 'Mutation', deleteRecipe?: { __typename?: 'EmptyResponse', _empty?: string | null | undefined } | null | undefined };

export type RegisterDeviceMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  deviceInfo: Scalars['JSONObject'];
}>;


export type RegisterDeviceMutation = { __typename?: 'Mutation', registerDevice?: { __typename?: 'EmptyResponse', _empty?: string | null | undefined } | null | undefined };

export type RequestAuthorizationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestAuthorizationMutation = { __typename?: 'Mutation', requestAuthorization?: { __typename?: 'EmptyResponse', _empty?: string | null | undefined } | null | undefined };

export type UpdateRecipeMutationVariables = Exact<{
  recipeId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  ingredients: Array<InputMaybe<IngredientInput>> | InputMaybe<IngredientInput>;
  instructions: Array<InputMaybe<InstructionInput>> | InputMaybe<InstructionInput>;
  image?: InputMaybe<ImageInput>;
}>;


export type UpdateRecipeMutation = { __typename?: 'Mutation', updateRecipe: { __typename?: 'Recipe', id: string } };

export type RecipeQueryVariables = Exact<{
  recipeId: Scalars['ID'];
}>;


export type RecipeQuery = { __typename?: 'Query', recipe: { __typename?: 'Recipe', id: string, title: string, description: string, ingredients: Array<{ __typename?: 'Ingredient', id: string, type: string, text: string }>, instructions: Array<{ __typename?: 'Instruction', id: string, type: string, text: string }>, image?: { __typename?: 'Image', signedUrl: string, blurHash: string, key?: string | null | undefined, ext?: string | null | undefined, filename?: string | null | undefined, mimeType?: string | null | undefined } | null | undefined } };

export type SessionUserRecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionUserRecipesQuery = { __typename?: 'Query', sessionUser: { __typename?: 'User', id: string, recipes: Array<{ __typename?: 'Recipe', id: string, title: string }> } };


export const AuthorizeDocument = gql`
    mutation authorize($token: String!) {
  authorize(token: $token) {
    sessionToken
  }
}
    `;
export type AuthorizeMutationFn = Apollo.MutationFunction<AuthorizeMutation, AuthorizeMutationVariables>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAuthorizeMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeMutation, AuthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(AuthorizeDocument, options);
      }
export type AuthorizeMutationHookResult = ReturnType<typeof useAuthorizeMutation>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<AuthorizeMutation, AuthorizeMutationVariables>;
export const CreateImageUploadUrlDocument = gql`
    mutation createImageUploadUrl {
  createImageUploadUrl {
    url
  }
}
    `;
export type CreateImageUploadUrlMutationFn = Apollo.MutationFunction<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>;

/**
 * __useCreateImageUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateImageUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageUploadUrlMutation, { data, loading, error }] = useCreateImageUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateImageUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>(CreateImageUploadUrlDocument, options);
      }
export type CreateImageUploadUrlMutationHookResult = ReturnType<typeof useCreateImageUploadUrlMutation>;
export type CreateImageUploadUrlMutationResult = Apollo.MutationResult<CreateImageUploadUrlMutation>;
export type CreateImageUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateImageUploadUrlMutation, CreateImageUploadUrlMutationVariables>;
export const CreateRecipeDocument = gql`
    mutation createRecipe($title: String!) {
  createRecipe(title: $title) {
    id
    title
  }
}
    `;
export type CreateRecipeMutationFn = Apollo.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, options);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = Apollo.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const DeleteRecipeDocument = gql`
    mutation deleteRecipe($recipeId: ID!) {
  deleteRecipe(recipeId: $recipeId) {
    _empty
  }
}
    `;
export type DeleteRecipeMutationFn = Apollo.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useDeleteRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, options);
      }
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = Apollo.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = Apollo.BaseMutationOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>;
export const RegisterDeviceDocument = gql`
    mutation registerDevice($deviceId: ID!, $deviceInfo: JSONObject!) {
  registerDevice(deviceId: $deviceId, deviceInfo: $deviceInfo) {
    _empty
  }
}
    `;
export type RegisterDeviceMutationFn = Apollo.MutationFunction<RegisterDeviceMutation, RegisterDeviceMutationVariables>;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeviceMutation, { data, loading, error }] = useRegisterDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceInfo: // value for 'deviceInfo'
 *   },
 * });
 */
export function useRegisterDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RegisterDeviceMutation, RegisterDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterDeviceMutation, RegisterDeviceMutationVariables>(RegisterDeviceDocument, options);
      }
export type RegisterDeviceMutationHookResult = ReturnType<typeof useRegisterDeviceMutation>;
export type RegisterDeviceMutationResult = Apollo.MutationResult<RegisterDeviceMutation>;
export type RegisterDeviceMutationOptions = Apollo.BaseMutationOptions<RegisterDeviceMutation, RegisterDeviceMutationVariables>;
export const RequestAuthorizationDocument = gql`
    mutation requestAuthorization($email: String!) {
  requestAuthorization(email: $email) {
    _empty
  }
}
    `;
export type RequestAuthorizationMutationFn = Apollo.MutationFunction<RequestAuthorizationMutation, RequestAuthorizationMutationVariables>;

/**
 * __useRequestAuthorizationMutation__
 *
 * To run a mutation, you first call `useRequestAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthorizationMutation, { data, loading, error }] = useRequestAuthorizationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<RequestAuthorizationMutation, RequestAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAuthorizationMutation, RequestAuthorizationMutationVariables>(RequestAuthorizationDocument, options);
      }
export type RequestAuthorizationMutationHookResult = ReturnType<typeof useRequestAuthorizationMutation>;
export type RequestAuthorizationMutationResult = Apollo.MutationResult<RequestAuthorizationMutation>;
export type RequestAuthorizationMutationOptions = Apollo.BaseMutationOptions<RequestAuthorizationMutation, RequestAuthorizationMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation updateRecipe($recipeId: ID!, $title: String!, $description: String!, $ingredients: [IngredientInput]!, $instructions: [InstructionInput]!, $image: ImageInput) {
  updateRecipe(
    recipeId: $recipeId
    title: $title
    description: $description
    ingredients: $ingredients
    instructions: $instructions
    image: $image
  ) {
    id
  }
}
    `;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      ingredients: // value for 'ingredients'
 *      instructions: // value for 'instructions'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const RecipeDocument = gql`
    query Recipe($recipeId: ID!) {
  recipe(recipeId: $recipeId) {
    id
    title
    description
    ingredients {
      id
      type
      text
    }
    instructions {
      id
      type
      text
    }
    image {
      signedUrl
      blurHash
      key
      ext
      filename
      mimeType
    }
  }
}
    `;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useRecipeQuery(baseOptions: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
      }
export function useRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeQueryResult = Apollo.QueryResult<RecipeQuery, RecipeQueryVariables>;
export const SessionUserRecipesDocument = gql`
    query SessionUserRecipes {
  sessionUser {
    id
    recipes {
      id
      title
    }
  }
}
    `;

/**
 * __useSessionUserRecipesQuery__
 *
 * To run a query within a React component, call `useSessionUserRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionUserRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUserRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionUserRecipesQuery(baseOptions?: Apollo.QueryHookOptions<SessionUserRecipesQuery, SessionUserRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionUserRecipesQuery, SessionUserRecipesQueryVariables>(SessionUserRecipesDocument, options);
      }
export function useSessionUserRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionUserRecipesQuery, SessionUserRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionUserRecipesQuery, SessionUserRecipesQueryVariables>(SessionUserRecipesDocument, options);
        }
export type SessionUserRecipesQueryHookResult = ReturnType<typeof useSessionUserRecipesQuery>;
export type SessionUserRecipesLazyQueryHookResult = ReturnType<typeof useSessionUserRecipesLazyQuery>;
export type SessionUserRecipesQueryResult = Apollo.QueryResult<SessionUserRecipesQuery, SessionUserRecipesQueryVariables>;