import { useCallback } from "react";
import { useHistory } from "react-router";
import { useSession } from "../../../hooks/services/useSession";

export const useLogoutPageService = () => {
  const session = useSession();
  const history = useHistory();

  const onLogout = useCallback(async () => {
    await session.logout();
    history.push('/');
  }, [session, history]);

  return {
    onLogout,
  }
}

