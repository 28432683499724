import { useState, useEffect } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import ApolloClientService from '../services/ApolloClient';

export const useApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    (async () => {
      const { client } = await ApolloClientService.getInstance();

      setClient(client);
    })();
  }, []);

  return { client };
}
