import { useCallback, useEffect, useRef } from 'react';
import Mousetrap, { ExtendedKeyboardEvent, MousetrapInstance } from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';

export type KeybindingHandler = (event: ExtendedKeyboardEvent) => void;

export const useKeybinding = (pattern: string, handler: KeybindingHandler) => {
  const ref = useRef<MousetrapInstance>();

  const wrappedHandler = useCallback((event: ExtendedKeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    handler(event)

    return false;
  }, [handler]);
  
  useEffect(() => {
    ref.current = new Mousetrap();

    // @ts-expect-error
    ref.current.bindGlobal(pattern, wrappedHandler);

    return () => {
      if (ref.current) {
        ref.current.reset();
        ref.current = undefined;
      }
    }
  }, [pattern, wrappedHandler]);
}