export interface ButtonProps {
  type?: 'button' | 'submit';
  text?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingText?: string;
  ciSelector?: string;
  onPress?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  text,
  isDisabled,
  isLoading,
  isLoadingText,
  ciSelector,
  onPress,
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onPress}
      data-ci={ciSelector}
    >
      {isLoading ? isLoadingText || text : text}
    </button>
  );
}

export default Button;
