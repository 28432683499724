import { useTranslation } from "react-i18next";
import RecipesGrid from "../../../atomic/organisms/RecipesGrid";
import RecipesTemplate from "../../../atomic/templates/RecipesTemplate";
import NavigationContainer from "../../containers/NavigationContainer";
import { useRecipesPageService } from "./service";

const RecipesPage = () => {
  const { t } = useTranslation('recipes');
  
  const {
    recipes,
    onCreate,
    isCreating,
    onDelete,
  } = useRecipesPageService();

  const createButton = {
    text: t('createButton.text'),
    onPress: onCreate,
    isLoading: isCreating,
  };

  return (
    <RecipesTemplate
      navigation={<NavigationContainer />}
      grid={(
        <RecipesGrid
          recipes={recipes}
          createButton={createButton}
          onDelete={onDelete}
        />
      )}
    />
  );
}

export default RecipesPage;
