import axios from 'axios';
import { useCallback } from 'react';
import { useCreateImageUploadUrlMutation } from '../../graphql/generated';

export type Image = {
  signedUrl: string;
  blurHash: string;
  key: string;
  ext: string;
  filename: string;
  mimeType: string;
};

export const useUploadImages = () => {
  const [createImageUploadUrl] = useCreateImageUploadUrlMutation();
  
  const onUploadImages = useCallback((files: Blob[]): Promise<Image[]> => (
    Promise.all(files.map(async (file) => {
      try {
        const uploadRequestResponse = await createImageUploadUrl();

        if (!uploadRequestResponse.data?.createImageUploadUrl.url) {
          throw new Error('No upload url');
        }

        const formData = new FormData();
        formData.append('file', file);

        const uploadResponse = await axios.post(uploadRequestResponse.data?.createImageUploadUrl.url, formData);

        if (!uploadResponse.data) {
          throw new Error('Upload failed');
        }

        const image: Image = {
          signedUrl: uploadResponse.data.signedUrl,
          key: uploadResponse.data.image.key,
          blurHash: uploadResponse.data.image.meta.blurHash,
          ext: uploadResponse.data.image.meta.ext,
          filename: uploadResponse.data.image.meta.filename,
          mimeType: uploadResponse.data.image.meta.mimeType,
        }

        return image;
      } catch (error) {
        throw error;
      }
    }))
  ), [createImageUploadUrl]);

  return [onUploadImages] as const;
};

export const useUploadImage = () => {
  const [uploadImages] = useUploadImages();

  const onUploadImage = useCallback(async (file: Blob) => {
    const [image] = await uploadImages([file]);
    return image;
  }, [uploadImages])

  return [onUploadImage] as const;
};