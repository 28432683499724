import { useCallback, useEffect } from 'react';
import { useRegisterDeviceMutation } from '../graphql/generated';
import DeviceInfo from '../services/DeviceInfo';

export const useRegisterDevice = () => {
  const [mutate] = useRegisterDeviceMutation();

  const registerDevice = useCallback(async () => {
    const deviceId = await DeviceInfo.getId();
    const deviceInfo = DeviceInfo.getInfo();

    await mutate({
      variables: {
        deviceId,
        deviceInfo,
      },
    });
  }, [mutate]);

  useEffect(() => {
    registerDevice();
  }, [registerDevice]);
}