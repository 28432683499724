import Box from "../../atoms/Box";
import Text from "../../atoms/Text";

export interface FormControlProps {
  label?: string | React.ReactNode;
  error?: string;
}

const FormControl: React.FC<FormControlProps> = ({
  label,
  error,
  children,
}) => {
  return (
    <Box>
      <label>
        {label && <Text>{label}</Text>}
        {children}
      </label>
      {error && <Text>{error}</Text>}
    </Box>
  );
}

export default FormControl;
