import Page from "../../layout/Page";

export interface SettingsTemplateProps {
  navigation: React.ReactNode;
  content: React.ReactNode;
}

const SettingsTemplate: React.FC<SettingsTemplateProps> = ({
  navigation,
  content,
}) => {
  return (
    <Page
      navigation={navigation}
      content={content}
    />
  );
}

export default SettingsTemplate;
