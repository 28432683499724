import React, { useCallback, useRef } from "react";
import { useEffect } from "react";

export interface IngredientEditorProps {
  ingredient: {
    id: string;
    type: 'headline' | 'item';
    text: string;
  };
  autoFocus?: boolean;
  onKeyAdd: () => void;
  onKeyRemove: () => void;
  onRemove: () => void;
  onUpdate: (text: string) => void;
}

const IngredientEditor: React.FC<IngredientEditorProps> = ({
  ingredient,
  autoFocus,
  onKeyAdd,
  onKeyRemove,
  onRemove,
  onUpdate,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onKeyAdd();
    } else if (event.key === 'Backspace') {
      if (ingredient.text === '') {
        onKeyRemove();
        event.preventDefault();
      }
    }
  }, [ingredient, onKeyAdd, onKeyRemove]);

  useEffect(() => {
    if (!inputRef.current) return;

    if (autoFocus) {
      inputRef.current.focus();  
    }
  }, [autoFocus])

  return (
    <>
      {ingredient.type}
      <input
        type="text"
        value={ingredient.text}
        onChange={e => onUpdate(e.target.value)}
        onKeyDown={onKeyDown}
        ref={inputRef}
      />
      <button type="button" onClick={onRemove}>remove</button>
    </>
  );
}

export default IngredientEditor;
