import * as localStorage from 'local-storage';

export default class ClientStorage {
  public static async set<T>(key: string, value: any) {
    localStorage.set<T>(key, value);
  }

  public static async get<T>(key: string) {
    return localStorage.get<T>(key);
  }

  public static async remove(key: string) {
    localStorage.remove(key);
  }
  
  public static async clear() {
    localStorage.clear();
  }
}
