import { GraphQLError } from 'graphql';

export type ErrorMap = Record<string, boolean>;

export const getErrorCodes = (error: any): ErrorMap => {
  try {
    if (error.message.includes('NetworkError')) {
      return { 'NETWORK_REQUEST_FAILED': true };
    }

    const errorMap = error.graphQLErrors.reduce((acc: ErrorMap, graphQLError: GraphQLError) => ({
      ...acc,
      [graphQLError.extensions?.code]: true,
    }), {});

    if (Object.keys(errorMap).length === 0) {
      throw error;
    }

    return errorMap;
  } catch (thrownError) {
    console.log('Unknown error', thrownError, error);
  }

  return { 'UNKNOWN_ERROR': true };
}