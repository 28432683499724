import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import DeviceInfo from './DeviceInfo';
import Environment from './Environment';
import SessionService from './Session';

export default class ApolloClientService {
  private static instance: ApolloClientService;

  public client: ApolloClient<NormalizedCacheObject> | undefined;

  private constructor() {}

  private async initialize() {
    try {
      console.log('Initializating apollo client...')
      const uri = Environment.apiUrl;

      const cache = new InMemoryCache();
      
      const httpLink = createHttpLink({
        uri,
      });
      
      const authLink = setContext(async (_, { headers }) => {
        const deviceId = await DeviceInfo.getId();
        const token = await SessionService.getToken();

        const newHeaders = {
          ...headers,
          'x-device': deviceId,
        };

        if (token) {
          newHeaders['authorization'] = `Bearer source="user" "${token}"`;
        }

        return {
          headers: newHeaders,
        }
      });

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            console.log('[GraphQL error]:', error);
          });
        }
    
        if (networkError) {
          console.log('[Network error]:', networkError);
        }
      });

      const client = new ApolloClient({
        link: authLink.concat(errorLink).concat(httpLink),
        cache,
        // defaultOptions: {
        //   watchQuery: {
        //     // fetchPolicy: 'cache-and-network',
        //     fetchPolicy: 'network-only',
        //     errorPolicy: 'none',
        //   },
        //   query: {
        //     // fetchPolicy: 'cache-first',
        //     fetchPolicy: 'network-only',
        //     errorPolicy: 'none',
        //   },
        //   mutate: {
        //     errorPolicy: 'none'
        //   }
        // },
      });

      this.client = client;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static async getInstance() {
    if (!ApolloClientService.instance) {
      ApolloClientService.instance = new ApolloClientService();
      await ApolloClientService.instance.initialize();
    }

    return ApolloClientService.instance;
  }
}
