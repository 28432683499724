import React, { useCallback, useState } from "react";

export type FormField<T> = FormFieldOptions<T> & {
  label: string | React.ReactNode;
  placeholder: string;
  isDisabled: boolean;
  
  value: T,
  setValue: (value: T) => void;
  onChange: (value: T) => void;

  error?: string,
  setError: (error?: string) => void;
  resetError: () => void;

  validate: () => boolean;
}

export type FormFieldOptions<T> = {
  label?: string | React.ReactNode;
  placeholder?: string;
  isDisabled?: boolean;

  validator?: (value: T) => string | undefined;
  validationErrors?: Record<string, string>;
}

export const useFormField = <T extends any>(defaultValue: T, options?: FormFieldOptions<T>): FormField<T> => {
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<string>();

  const validate = useCallback(() => {
    if (!options?.validator) {
      throw new Error('No validator specified for form field');
    }

    const error = options.validator(value);

    if (error) {
      setError(options?.validationErrors ? options.validationErrors[error] : error);
    } else {
      setError(undefined)
    }

    return error === undefined;
  }, [value, options]);

  const resetError = useCallback(() => {
    setError(undefined);
  }, []);

  return {
    value,
    setValue,
    onChange: setValue,

    error,
    setError,
    resetError,

    label: options?.label ?? '',
    placeholder: options?.placeholder ?? '',
    isDisabled: options?.isDisabled ?? false,

    validate,
  }
}
