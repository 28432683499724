import Button, { ButtonProps } from "../../atoms/Button";
import Heading from "../../atoms/Heading";
import ImageComponent, { ImageProps } from "../../atoms/Image";
import Input from "../../atoms/Input";
import Link from "../../atoms/Link";
import Text from "../../atoms/Text";
import ErrorMessage from "../../molecules/ErrorMessage";
import IngredientsEditor, { IngredientsEditorProps } from "../../molecules/IngredientsEditor";
import InstructionsEditor from "../../molecules/InstructionsEditor";
import LoadingMessage from "../../molecules/LoadingMessage";

export interface RecipeEditorProps {
  isLoading: boolean;
  undoButton: Pick<Required<ButtonProps>, 'text' | 'onPress' | 'isDisabled'>;
  redoButton: Pick<Required<ButtonProps>, 'text' | 'onPress' | 'isDisabled'>;
  saveButton: Pick<Required<ButtonProps>, 'text' | 'onPress' | 'isDisabled'>;
  doneButton: Pick<Required<ButtonProps>, 'text'>;
  recipeId: string;
  title: string;
  description: string;
  instructions: IngredientsEditorProps['ingredients'];
  ingredients: IngredientsEditorProps['ingredients'];
  image?: ImageProps;
  onTitleChange: (text: string) => void;
  onDescriptionChange: (text: string) => void;
  onImageChange: (files: Blob[]) => void;
  onImageRemove: () => void;
  onRemoveIngredient: (ingredientId: string) => void;
  onUpdateIngredient: (ingredientId: string, text: string) => void;
  onAddIngredient: (index: number) => void;
  onMoveIngredient: (oldIndex: number, newIndex: number) => void;
  onRemoveInstruction: (instructionId: string) => void;
  onUpdateInstruction: (instructionId: string, text: string) => void;
  onAddInstruction: (index: number) => void;
  onMoveInstruction: (oldIndex: number, newIndex: number) => void;
}

const RecipeEditor: React.FC<RecipeEditorProps> = ({
  isLoading,
  undoButton,
  redoButton,
  saveButton,
  doneButton,
  recipeId,
  title,
  description,
  instructions,
  ingredients,
  image,
  onTitleChange,
  onImageChange,
  onImageRemove,
  onDescriptionChange,
  onAddIngredient,
  onUpdateIngredient,
  onRemoveIngredient,
  onMoveIngredient,
  onAddInstruction,
  onUpdateInstruction,
  onRemoveInstruction,
  onMoveInstruction,
}) => {
  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Input
        value={title}
        onChangeText={onTitleChange}
      />

      <Input
        value={description}
        onChangeText={onDescriptionChange}
      />

      {image && (
        <>
          <ImageComponent
            url={image.url}
            blurHash={image.blurHash}
          />
          <button type="button" onClick={onImageRemove}>x</button>
        </>
      )}

      <input
        type="file"
        onChange={(e) => onImageChange(e.target.files as unknown as Blob[])}
      />

      <h4>ingredients</h4>

      <IngredientsEditor
        ingredients={ingredients}
        onAdd={onAddIngredient}
        onUpdate={onUpdateIngredient}
        onRemove={onRemoveIngredient}
        onMove={onMoveIngredient}
      />
      
      <h4>instructions</h4>

      <InstructionsEditor
        instructions={instructions}
        onAdd={onAddInstruction}
        onUpdate={onUpdateInstruction}
        onRemove={onRemoveInstruction}
        onMove={onMoveInstruction}
      />

      <hr />

      <Button
        text={undoButton.text}
        onPress={undoButton.onPress}
        isDisabled={undoButton.isDisabled}
      />

      <Button
        text={redoButton.text}
        onPress={redoButton.onPress}
        isDisabled={redoButton.isDisabled}
      />

      <Button
        text={saveButton.text}
        onPress={saveButton.onPress}
        isDisabled={saveButton.isDisabled}
      />

      <Link to={`/recipes/${recipeId}`}>
        <Button
          text={doneButton.text}
        />
      </Link>
    </>
  );
}

export default RecipeEditor;
