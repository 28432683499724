import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { RecipeQuery, useRecipeQuery } from '../../../graphql/generated';
import { useQueryResult } from '../../../hooks/helpers/useQueryResult';

export const useRecipePageService = () => {
  const { t } = useTranslation('recipe');
  const { recipeId } = useParams<{ recipeId: string }>();

  const recipeQuery = useRecipeQuery({
    variables: {
      recipeId,
    },
  });

  const recipe = useQueryResult<RecipeQuery, RecipeQuery['recipe']>(recipeQuery,
    (data) => data.recipe,
    () => t('recipeErrors.unknown'),
  );

  return {
    recipe,
  };
};