import Page from "../../layout/Page";

export interface RecipeTemplateProps {
  navigation: React.ReactNode;
  content: React.ReactNode;
}

const RecipeTemplate: React.FC<RecipeTemplateProps> = ({
  navigation,
  content,
}) => {
  return (
    <Page
      navigation={navigation}
      content={content}
    />
  );
}

export default RecipeTemplate;
