import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useError } from "../../../hooks/helpers/useError";
import { useTextFormField } from "../../../hooks/forms/useTextFormField";
import { useRequestAuthorizationMutation } from "../../../graphql/generated";
import { getErrorCodes } from "../../../utils/errors";

export const useAuthContainerService = (i18nNamespace: 'login' | 'signup') => {
  const { t } = useTranslation(i18nNamespace);
  const [state, setState] = useState<'initial' | 'success'>('initial');
  const [error, setError, resetError] = useError();
  const [requestAuthorization, { loading }] = useRequestAuthorizationMutation();
  const isLoading = loading;
  const isDisabled = isLoading;

  const email = useTextFormField('', {
    label: t('email.label'),
    placeholder: t('email.placeholder'),
    isDisabled,
  });

  const onSubmit = useCallback(async () => {
    email.resetError();
    resetError();

    try {
      await requestAuthorization({
        variables: {
          email: email.value,
        },
      });

      setState('success');
    } catch (error) {
      const errorCodes = getErrorCodes(error);

      if (errorCodes.INVALID_EMAIL) {
        email.setError(t('errors.invalidEmail'));
      } else if (errorCodes.NETWORK_REQUEST_FAILED) {
        setError(t('errors.network'));
      } else {
        setError(t('errors.unknown'));
      }
    }
  }, [email, requestAuthorization, setError, resetError, t]);

  const onReset = useCallback(() => {
    email.setValue('');
    setState('initial');
  }, [email]);

  const submitButton = {
    type: 'submit' as const,
    text: t('submitButton.text'),
    isLoadingText: t('submitButton.isLoadingText'),
    isLoading,
    isDisabled,
  };
  

  return {
    state,
    error,
    email,
    submitButton,
    onSubmit,
    onReset,
  }
}

