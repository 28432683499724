import {
  Container,
  Navigation,
  Content,
} from './styles';

export interface PageProps {
  navigation: React.ReactNode,
  content: React.ReactNode,
}

const Page: React.FC<PageProps> = ({
  navigation,
  content,
}) => {
  return (
    <Container>
      <Navigation>
        {navigation}
      </Navigation>
      <Content>
        {content}
      </Content>
    </Container>
  );
}

export default Page;
