import React from "react";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import { Entities } from "../../types";

export interface RecipesGridProps {
  recipes: Entities<{
    id: string;
    title: string;
  }>;
  createButton: {
    text: string;
    onPress: () => void;
    isLoading?: boolean;
  };
  onDelete: (recipeId: string) => void;
}

const RecipesGrid: React.FC<RecipesGridProps> = ({
  recipes,
  createButton,
  onDelete,
}) => {
  return (
    <>
      {(() => {
        if (recipes.status === 'loading') {
          return <div>loading</div>
        }

        if (recipes.status === 'error') {
          return <div>{recipes.error}</div>
        }

        return (
          <div>
            {recipes.data.map((recipe) => (
              <div key={recipe.id}>
                <Link to={`/recipes/${recipe.id}`}>
                  {recipe.title}
                </Link>

                <button type="button" onClick={() => onDelete(recipe.id)}>remove</button>
              </div>
            ))}
          </div>
        );
      })()}
      
      <Button
        {...createButton}
      />
    </>
  );
}

export default RecipesGrid;
