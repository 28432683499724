import { Ingredient, Instruction, Recipe, State } from './state';
import { Action } from './api';
import { arrayMove } from 'react-movable';

export const setRecipe: Action = {
  do: (recipe: Recipe) => (state: State) => {
    state.recipe = recipe;
  }
}

export const setTitle: Action = {
  history: (title: string) => {
    return {
      throttle: !title ? undefined : {
        key: 'title',
        delay: 1000,
      },
    }
  },
  do: (title: string) => (state: State) => {
    state.recipe.title = title;
  }
}

export const setDescription: Action = {
  history: (description: string) => {
    return {
      throttle: !description ? undefined : {
        key: 'description',
        delay: 1000,
      },
    }
  },
  do: (description: string) => (state: State) => {
    state.recipe.description = description;
  }
}

export const setImage: Action = {
  history: true,
  do: (image: Recipe['image']) => (state: State) => {
    state.recipe.image = image;
  }
}

export const addIngredient: Action = {
  history: true,
  do: (index: number, ingredient: Ingredient) => (state: State) => {
    state.recipe.ingredients.splice(index, 0, ingredient);
  }
}

export const updateIngredient: Action = {
  history: (ingredientId: string, text: string) => {
    return {
      throttle: !text ? undefined : {
        key: 'text' + ingredientId,
        delay: 1000,
      },
    }
  },
  do: (ingredientId: string, text: string) => (state: State) => {
    state.recipe.ingredients = state.recipe.ingredients.map((ingredient) => {
      if (ingredient.id === ingredientId) {
        ingredient.text = text;
      }

      return ingredient;
    })
  }
}

export const removeIngredient: Action = {
  history: true,
  do: (ingredientId: string) => (state: State) => {
    state.recipe.ingredients = state.recipe.ingredients.filter(ingredient => ingredient.id !== ingredientId)
  }
}

export const moveIngredient: Action = {
  history: true,
  do: (oldIndex: number, newIndex: number) => (state: State) => {
    state.recipe.ingredients = arrayMove(state.recipe.ingredients, oldIndex, newIndex);
  }
}


export const addInstruction: Action = {
  history: true,
  do: (index: number, instruction: Instruction) => (state: State) => {
    state.recipe.instructions.splice(index, 0, instruction);
  }
}

export const updateInstruction: Action = {
  history: (instructionId: string, text: string) => {
    return {
      throttle: !text ? undefined : {
        key: 'text' + instructionId,
        delay: 1000,
      },
    }
  },
  do: (instructionId: string, text: string) => (state: State) => {
    state.recipe.instructions = state.recipe.instructions.map((instruction) => {
      if (instruction.id === instructionId) {
        instruction.text = text;
      }

      return instruction;
    })
  }
}

export const removeInstruction: Action = {
  history: true,
  do: (instructionId: string) => (state: State) => {
    state.recipe.instructions = state.recipe.instructions.filter(instruction => instruction.id !== instructionId)
  }
}

export const moveInstruction: Action = {
  history: true,
  do: (oldIndex: number, newIndex: number) => (state: State) => {
    state.recipe.instructions = arrayMove(state.recipe.instructions, oldIndex, newIndex);
  }
}
