import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import RecipeEditor from "../../../atomic/organisms/RecipeEditor";
import RecipeTemplate from "../../../atomic/templates/RecipeTemplate";
import { useKeybinding } from "../../../hooks/helpers/useKeybinding";
import NavigationContainer from "../../containers/NavigationContainer";
import { useRecipeEditorPageService } from "./service";

export interface RecipeEditorPageProps {}

const RecipeEditorPage: React.FC<RecipeEditorPageProps> = () => {
  const { t } = useTranslation('recipe-editor');

  const {
    isLoading,
    isSaving,
    recipeId,
    title,
    description,
    ingredients,
    instructions,
    image,
    onTitleChange,
    onDescriptionChange,
    onImageChange,
    onImageRemove,
    onAddIngredient,
    onUpdateIngredient,
    onRemoveIngredient,
    onMoveIngredient,
    onAddInstruction,
    onUpdateInstruction,
    onRemoveInstruction,
    onMoveInstruction,
    hasUnsavedChanges,
    undo,
    redo,
    canUndo,
    canRedo,
    onSave,
  } = useRecipeEditorPageService();

  useEffect(() => {
    window.onbeforeunload = () => {
      return hasUnsavedChanges ? '' : undefined;
    }

    return () => {
      window.onbeforeunload = null;
    }
  }, [hasUnsavedChanges]);

  useKeybinding('mod+z', undo);
  useKeybinding('mod+shift+z', redo);
  useKeybinding('mod+s', onSave);

  const undoButton = {
    text: t('undo'),
    isDisabled: !canUndo,
    onPress: undo,
  };

  const redoButton = {
    text: t('redo'),
    isDisabled: !canRedo,
    onPress: redo
  };

  const saveButton = {
    text: t('save'),
    isDisabled: isSaving,
    onPress: onSave
  };

  const doneButton = {
    text: t('done'),
  };

  return (
    <>
      <RecipeTemplate
        navigation={<NavigationContainer />}
        content={(
          <RecipeEditor
            isLoading={isLoading}
            undoButton={undoButton}
            redoButton={redoButton}
            saveButton={saveButton}
            doneButton={doneButton}
            recipeId={recipeId}
            title={title}
            description={description}
            ingredients={ingredients}
            instructions={instructions}
            image={image}
            onTitleChange={onTitleChange}
            onDescriptionChange={onDescriptionChange}
            onImageChange={onImageChange}
            onImageRemove={onImageRemove}
            onAddIngredient={onAddIngredient}
            onUpdateIngredient={onUpdateIngredient}
            onRemoveIngredient={onRemoveIngredient}
            onMoveIngredient={onMoveIngredient}
            onAddInstruction={onAddInstruction}
            onUpdateInstruction={onUpdateInstruction}
            onRemoveInstruction={onRemoveInstruction}
            onMoveInstruction={onMoveInstruction}
          />
        )}
      />
      
      <Prompt
        when={hasUnsavedChanges}
        message="This page is asking you to confirm that you want to leave — information you've entered may not be saved."
      />
    </>
  );
}

export default RecipeEditorPage;
