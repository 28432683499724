import React from "react";
import Box from "../../atoms/Box";

export interface FooterProps {
  termsOfServiceLink: React.ReactNode;
  privacyPolicyLink: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({
  termsOfServiceLink,
  privacyPolicyLink
}) => {

  return (
    <>
      <Box>{termsOfServiceLink}</Box>
      <Box>{privacyPolicyLink}</Box>
    </>
  );
}

export default Footer;
