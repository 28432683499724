import React from 'react';

export interface HeadingProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading: React.FC<HeadingProps> = ({
  as,
  children,
}) => {
  return React.createElement(as, null, children);
}

export default Heading;
