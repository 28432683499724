import React, { useCallback } from "react";

export interface FormProps {
  onSubmit: () => void;
}

const Form: React.FC<FormProps> = ({
  onSubmit,
  children,
}) => {
  const onFormSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  }, [onSubmit])
  return (
    <form onSubmit={onFormSubmit}>
      {children}
    </form>
  );
}

export default Form;
