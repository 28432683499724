import Button from "../../../atomic/atoms/Button";
import Link from "../../../atomic/atoms/Link";
import SettingsTemplate from "../../../atomic/templates/SettingsTemplate";
import NavigationContainer from "../../containers/NavigationContainer";

const SettingsPage = () => {
  return (
    <SettingsTemplate
      navigation={<NavigationContainer />}
      content={(
        <>
          Settings
          <Link to="/logout">
            <Button text="Logout" />
          </Link>
        </>
      )}
    />
  );
}

export default SettingsPage;
