import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import { useRegisterDevice } from '../hooks/useRegisterDevice';

import EntryPage from './pages/EntryPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AuthorizePage from './pages/AuthorizePage';
import SignupPage from './pages/SignupPage';
import SettingsPage from './pages/SettingsPage';
import RecipesPage from './pages/RecipesPage';
import NotFoundPage from './pages/NotFoundPage';
import RecipePage from './pages/RecipePage';
import RecipeEditorPage from './pages/RecipeEditorPage';

const Router = () => {
  useRegisterDevice();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/sign-up">
          <SignupPage />
        </Route>

        <Route path="/login">
          <LoginPage />
        </Route>

        <Route path="/logout">
          <LogoutPage />
        </Route>
        
        <Route path="/authorize/:token">
          <AuthorizePage />
        </Route>

        <Route path="/recipes/:recipeId/edit">
          <RecipeEditorPage />
        </Route>

        <Route path="/recipes/:recipeId">
          <RecipePage />
        </Route>

        <Route path="/recipes">
          <RecipesPage />
        </Route>

        <Route path="/settings">
          <SettingsPage />
        </Route>

        <Route path="/:path">
          <NotFoundPage />
        </Route>

        <Route path="/">
          <EntryPage />
        </Route>
    </Switch>
    </BrowserRouter>
  );
}

export default Router;
