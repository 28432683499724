import * as BlurHash from 'blurhash';
import { useEffect, useRef } from 'react';

export interface ImageProps {
  url: string;
  blurHash?: string,
  description?: string;
}

const ImageComponent: React.FC<ImageProps> = ({
  url,
  blurHash,
  description,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  
  useEffect(() => {
    if (!imageRef.current || !blurHash) return;

    try {
      const { width, height } = imageRef.current;

      const pixels = BlurHash.decode(blurHash, width, height);

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      const imageData = ctx!.createImageData(width, height);
      imageData.data.set(pixels);
      ctx!.putImageData(imageData, 0, 0);

      const dataUrl = canvas.toDataURL('image/png');
      imageRef.current.src = dataUrl;
    } catch (error) {
      console.log(error);
    }
  }, [imageRef, blurHash]);

  useEffect(() => {
    const image = new Image();

    image.onload = () => {
      if (!imageRef.current) return;
      imageRef.current.src = image.src;
    }

    image.src = url;
  }, [url]);

  return (
    <img
      src={url}
      alt={description}
      ref={imageRef}
      width="200"
      height="200"
    />
  );
}

export default ImageComponent;
