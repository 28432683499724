import { useCallback, useState } from "react";
import { List } from 'react-movable';
import InstructionEditor, { InstructionEditorProps } from "../InstructionEditor";

export interface InstructionsEditorProps {
  instructions: InstructionEditorProps['instruction'][];
  onRemove: (instructionId: string) => void;
  onUpdate: (instructionId: string, text: string) => void;
  onAdd: (index: number) => void;
  onMove: (oldIndex: number, newIndex: number) => void;
}

const InstructionsEditor: React.FC<InstructionsEditorProps> = ({
  instructions,
  onRemove,
  onUpdate,
  onAdd,
  onMove,
}) => {
  const [indexFocus, setIndexFocus] = useState(-1);

  const onClickAdd = useCallback(() => {
    onAdd(instructions.length);
    setIndexFocus(instructions.length);
  }, [onAdd, instructions]);

  const onKeyAdd = useCallback((instructionId: string) => {
    const index = instructions.findIndex(instruction => instruction.id === instructionId);
    onAdd(index + 1);
    setIndexFocus(index + 1);
  }, [onAdd, instructions]);
  
  const onKeyRemove = useCallback((instructionId: string) => {
    const index = instructions.findIndex(instruction => instruction.id === instructionId);
    onRemove(instructionId);
    setIndexFocus(index - 1);
  }, [onRemove, instructions]);

  return (
    <>
      <List
        values={instructions}
        onChange={({ oldIndex, newIndex }) => {
          onMove(oldIndex, newIndex);
        }}
        renderList={({ children, props }) => (
          <div {...props}>
            {children}
          </div>
        )}
        renderItem={({ value: instruction, props, index }) => (
          <div {...props}>
            <InstructionEditor
              step={(index ?? 0) + 1}
              instruction={instruction}
              autoFocus={indexFocus === index}
              onKeyAdd={() => onKeyAdd(instruction.id)}
              onKeyRemove={() => onKeyRemove(instruction.id)}
              onRemove={() => onRemove(instruction.id)}
              onUpdate={(text) => onUpdate(instruction.id, text)}
            />
          </div>
        )}
      />

      <button type="button" onClick={onClickAdd}>
        Add
      </button>
    </>
  );
}

export default InstructionsEditor;
