import { useTranslation } from "react-i18next";
import Button from "../../../atomic/atoms/Button";
import Link from "../../../atomic/atoms/Link";
import Text from "../../../atomic/atoms/Text";
import AuthTemplate from "../../../atomic/templates/AuthTemplate";
import FooterContainer from "../../containers/FooterContainer";
import { useAuthorizePageService } from "./service";

const AuthorizePage = () => {
  const { t } = useTranslation('authorize');

  const {
    error,
  } = useAuthorizePageService();

  if (error) {
    return (
      <AuthTemplate
        form={(
          <>
            <Text>{error}</Text>

            <Link to="/login">
              <Button text={t('newLink')} />
            </Link>
          </>
        )}
        footer={<FooterContainer />}
      />
    );
  }

  return null;
}

export default AuthorizePage;
