import Text from "../../atoms/Text";

export interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message
}) => {
  return (
    <>
      <Text>{message}</Text>
    </>
  );
}

export default ErrorMessage;
