import ErrorMessage from "../ErrorMessage";

export interface FormErrorMessageProps {
  message?: string;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  message,
}) => {
  if (!message) {
    return null;
  }

  return (
    <ErrorMessage message={message} />
  );
}

export default FormErrorMessage;
