import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';

import { useApolloClient } from './hooks/useApolloClient';

import Router from './app/Router';
import ToastContext, { useCreateToastContext } from './atomic/contexts/ToastContext';
import Toasts from './atomic/organisms/Toasts';

const Main = () => {
  const { client } = useApolloClient();
  const toastContext = useCreateToastContext();

  return (
    <>
      {(() => {
        if (!client) {
          return null
        }
        
        return (
          <Suspense fallback="">
            <ApolloProvider client={client}>
              <ToastContext.Provider value={toastContext}>
                <Router />

                <Toasts />
              </ToastContext.Provider>
            </ApolloProvider>
          </Suspense>
        )
      })()}
    </>
  );
}

export default Main;
