import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../../atomic/organisms/Footer";

const FooterContainer = () => {
  const { t } = useTranslation('footer');

  return (
    <Footer
      privacyPolicyLink={(
        <Trans t={t} i18nKey="privacyPolicy" components={{
          privacy: <Link to="/privacy-policy" />
        }} />
      )}
      termsOfServiceLink={(
        <Trans t={t} i18nKey="termsOfService" components={{
          terms: <Link to="/terms-of-service" />
        }} />
      )}
    />
  );
}

export default FooterContainer;
